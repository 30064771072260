/**
 * @fileOverview H2 component
 * @name H2.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"

import fluidFonts from "utilities/fluidFonts"
import fluidValues from "utilities/fluidValues"
import { fontFamilySansSerif } from "gatsby-theme-fela/fonts"

/**
 * Home page Header H2
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled H2
 */
const h2 = ({tag = 'h2', styles, children}) => (
  React.createElement(tag, {className: styles.styles}, children)
)

/* Styles */
const styles = (props) => {
  switch (props.style) {
    default:
      return {
        fontFamily: fontFamilySansSerif,
        fontWeight: 600,
        whiteSpace: 'pre-wrap',
        marginTop: 0,
        letterSpacing: 0,
        ...fluidFonts({fontSize: [32, 42], lineHeight: [36, 46]}),
        extend: [
          {
            condition: !props.neo,
            style: {
              ...fluidValues({marginBottom: [24, 40]})
            }
          },
          {
            condition: props.content,
            style: {
              ...fluidValues({marginBottom: [12, 32]}),
            }
          },
          {
            condition: props.neo,
            style: {
              fontWeight: 800,
              marginBottom: 12
            }
          }
        ]
      }
  }
}

/* Component */
const H2 = connectFela({styles})(h2)
export default H2
